<template>
  <div>
    <div class="border p-3 rounded rounded-5 mb-3">
      <div class="mb-3">
        <span class="fw-bolder me-3">
          常用款式
          <span class="text-secondary">(加速您在新增款式的時間 ^O^)</span>
        </span>
        <button
          class="tw-btn tw-btn-success"
          @click="showModal('createStyles')"
        >
          新增款式
        </button>
      </div>
      <hr />
      <ul class="list-group list-group-numbered">
        <li class="list-group-item" v-for="data in styleList" :key="data.id">
          <div class="row">
            <div class="col-md-9 col-8 ps-md-5">{{ data.value }}</div>
            <div class="col-md-3 col-4">
              <button
                class="tw-btn tw-btn-success me-2 mb-2"
                @click="showModal('edit', data)"
              >
                編輯
              </button>
              <button
                v-if="data.selected"
                class="tw-btn tw-btn-warning me-2 mb-2"
                @click="updateStyles('pause', data)"
              >
                暫停
              </button>
              <button
                v-else
                class="tw-btn tw-btn-success me-2 mb-2"
                @click="updateStyles('pause', data)"
              >
                啟用
              </button>
              <button
                class="tw-btn tw-btn-danger"
                @click="showModal('delStyles', data)"
              >
                刪除
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- 新增款式的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="createStylesModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">新增款式</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="mb-3 alert alert-warning">
            注意 ! 每個款式字數最長為20字，每個款式之間請用全形逗號分開(EX:
            藍色，黑色，綠色)
          </p>
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="CommonStyles"
              placeholder="常用款式(240字)"
              maxlength="240"
              v-model="styleText"
            />
            <label for="CommonStyles">常用款式(240字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="createStyles"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 編輯款式的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editStylesModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">編輯款式</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="mb-3 alert alert-warning">
            注意 ! 每個款式字數最長為20字，每個款式之間請用全形逗號分開(EX:
            藍色，黑色，綠色)
          </p>
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="CommonStyles"
              placeholder="常用款式(240字)"
              maxlength="240"
              v-model="styleText"
            />
            <label for="CommonStyles">常用款式(240字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateStyles"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 刪除的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="delStylesModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5
            class="
              modal-title
              border-start
              ps-2
              border-5 border-danger
              text-danger
              fw-bold
            "
          >
            刪除
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p>一經刪除就不能再復原嘍 !</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="delStyles"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // modal
      createStylesModal: {},
      editStylesModal: {},
      delStylesModal: {},
      // data
      storeId: 0,
      serverToken: '',
      styleList: [],
      styleText: '',
      updateStatus: '',
      selectItems: [],
      // modal 內呈現選擇到的物件資訊
      propsSelectInfos: {
        show: 'name',
        data: []
      },
    }
  },
  created() {
    this.initialization()
    this.getStoreInfo()
  },
  mounted() {
    this.createModals(['createStylesModal', 'editStylesModal', 'delStylesModal'])
  },
  methods: {
    initialization() {
      this.storeId = this.$route.params.storeId
      this.serverToken = this.$methods.getCookie('serverToken')
    },
    showModal(status, item) {
      if (status === 'createStyles') {
        // 新增款式
        this.styleText = ''
        this.createStylesModal.show()
      } else if (status === 'edit') {
        // 編輯款式
        this.styleText = item.value
        this.selectItems = []
        this.selectItems.push(item)
        this.editStylesModal.show()
      } else if (status === 'delStyles') {
        // 刪除
        this.selectItems = []
        item.name = item.value
        this.selectItems.push(item)
        this.propsSelectInfos.data = this.selectItems
        this.delStylesModal.show()
      }
    },
    getStoreInfo() {
      this.$methods.switchLoading('show')
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getConfiguration}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            storeInfo.configuration.forEach(item => {
              if (item.category === 'commonStyles') {
                if (item.item === 'commonStyles') vm.styleList = item.settingValues
              }
            });
          }
          setTimeout(() => {
            vm.$methods.switchLoading('hide')
          }, 500)
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    createStyles() {
      const vm = this
      const createStylesApi = `${process.env.VUE_APP_API}/storeConfiguration/createSettingValue`
      const header = {
        authorization: this.serverToken
      }
      if (!this.styleText) return this.SweetAlert('other', '您尚未輸入任何款式')
      if (this.styleText.length > 240)  return this.SweetAlert('other', '您輸入的自數超過 240 字')
      const array = this.styleText.replace(/\s*/g, "").split('，')
      const error = array.some(item => {
        if (item.length > 20) return true
      })
      if (error) return this.SweetAlert('other', '您的款式其中字數有大於 20 字')
      const arrayClear = [...(new Set(array))]
      if (array.length !== arrayClear.length) return this.SweetAlert('other', '您輸入的款式有重複')
      const data = [{
        storeConfiguration: {
          category: 'commonStyles',
          item: 'commonStyles'
        },
        value: array.join('，'),
        selected: true,
        storeId: this.storeId
      }]
      this.$methods.switchLoading('show')
      $.ajax({
        type: 'POST',
        async: true,
        url: createStylesApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.createStylesModal.hide()
            vm.getStoreInfo()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 編輯/暫停
    updateStyles(status, value) {
      const vm = this
      const updateStylesApi = `${process.env.VUE_APP_API}/storeConfiguration/updateSettingValue`
      const header = {
        authorization: this.serverToken
      }
      let data = []
      if (status === 'pause') {
        // 暫停
        this.styleList.forEach(item => {
          if (item.id === value.id) {
            data.push({
              id: item.id,
              value: item.value,
              selected: !item.selected
            })
          } else {
            data.push({
              id: item.id,
              value: item.value,
              selected: item.selected
            })
          }
        })
      } else {
        // 編輯
        if (!this.styleText) return this.SweetAlert('other', '您尚未輸入任何款式')
        if (this.styleText.length > 240)  return this.SweetAlert('other', '您輸入的自數超過 240 字')
        const array = this.styleText.replace(/\s*/g, "").split('，')
        const error = array.some(item => {
          if (item.length > 20) return true
        })
        if (error) return this.SweetAlert('other', '您的款式其中字數有大於 20 字')
        const arrayClear = [...(new Set(array))]
        if (array.length !== arrayClear.length) return this.SweetAlert('other', '您輸入的款式有重複')
        this.styleList.forEach(item => {
          if (item.id === this.selectItems[0].id) {
            data.push({
              id: item.id,
              value: array.join('，'),
              selected: item.selected
            })
          } else {
            data.push({
              id: item.id,
              value: item.value,
              selected: item.selected
            })
          }
        })
      }
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateStylesApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.editStylesModal.hide()
            vm.getStoreInfo()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 刪除
    delStyles() {
      const vm = this
      const delStylsApi = `${process.env.VUE_APP_API}/storeConfiguration/deleteSettingValue`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append('settingValueIds', this.selectItems[0].id)
      $.ajax({
        type: 'DELETE',
        async: true,
        url: delStylsApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.delStylesModal.hide()
            vm.getStoreInfo()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
  },
}
</script>